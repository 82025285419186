import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

type SeoComponentProps = {
  title?: string
  description?: string
  imageUrl?: any
}

function SeoComponent(props: SeoComponentProps) {
  const { title, description, imageUrl } = props

  /* QUERY */
  const data = useStaticQuery(graphql`
    query {
      sanityLinksGlobalImage {
        linksImage {
          asset {
            url
          }
        }
      }
      allSanitySiteFavicon {
        nodes {
          favicon {
            image {
              asset {
                url
              }
            }
          }
        }
      }
    }
  `)

  const faviconImg = data.allSanitySiteFavicon.nodes[0].favicon.image.asset.url
  const metaImageUrl = `${
    imageUrl ? imageUrl : data.sanityLinksGlobalImage.linksImage.asset.url
  }?4362984378${Date.now()}`

  return (
    <Helmet htmlAttributes={{ lang : 'en-US' }}>
      <meta charSet='utf-8' />
      <meta name='description' content={description} />

      <meta property='og:type' content='website' />
      <meta
        property='og:url'
        content={(typeof window !== 'undefined' && window?.location?.href) || 'https://www.wanabrands.com/'}
      />
      <meta property='og:description' content={description} />
      <meta property='og:title' content={title} />
      <meta property='og:image' content={metaImageUrl} />
      <meta property='og:image:width' content='256' />
      <meta property='og:image:height' content='256' />

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@WanaBrands' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={metaImageUrl} />
      <meta name='twitter:image:alt' content='image_description_here' />

      <link rel='shortcut icon' href={faviconImg} />

      <title>{title}</title>
      <script src='https://cdn.storerocket.io/widget.js'></script>
      <script src='https://www.cognitoforms.com/f/iframe.js'></script>
      <script>
        {`window.__lc = window.__lc || {};
            window.__lc.license = 12013311;
            ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))`}
      </script>
      <script
        async
        type='text/javascript'
        src='https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=XF7J9V'
      ></script>
    </Helmet>
  )
}

export default SeoComponent
